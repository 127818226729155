<template>
  <div>
    <div id="Rank" :style="`right:${right}`">
      <div class="rankitem" style="width: 1rem">
        <div class="flbox" @click="tocdkbox">
          <img src="../../assets/images/Rank/mb-rank-iconbox.png" alt="" />
        </div>
        <!-- <p>福利宝箱</p> -->
      </div>
      <div class="rankitem">
        <div
          class="imgbox"
          @click="
            () => {
              (this.welfare = true), (this.Dailywelfare = true);
            }
          "
        >
          <img src="../../assets/images/Rank/mb-rank-icon4.png" alt="" />
        </div>
        <p>红包中心</p>
      </div>
      <a target="_blank" :href="querydata.qq_group_link" class="rankitem">
        <div class="imgbox">
          <img src="../../assets/images/Rank/mb-rank-icon2.png" alt="" />
        </div>
        <p>一键加群</p>
      </a>
      <a
        class="rankitem"
        target="_blank"
        :href="`mqqwpa://im/chat?chat_type=wpa&uin=${querydata.service_qq}&version=1&src_type=web&web_src=oicqzone.com`"
      >
        <div class="imgbox">
          <img src="../../assets/images/Rank/mb-rank-icon3.png" alt="" />
        </div>
        <p>在线客服</p>
      </a>
    </div>
    <!-- 福利中心弹出 -->
    <van-overlay :lock-scroll="false" :show="welfare">
      <div class="duwelfare" v-if="welfare">
        <div class="title">
          <div class="center">活动福利</div>
          <img
            class="point"
            style="
              display: block;
              width: 0.16rem;
              position: absolute;
              right: 0.2rem;
            "
            @click="
              () => {
                (this.welfare = false),
                  (this.flbtnclick = 2),
                  (this.changeerjibtn = 1),
                  (this.yesterday = false);
              }
            "
            src="@/assets/images/new/bg7.png"
            alt=""
          />
        </div>
        <div class="line"></div>
        <!-- 活动主体 -->
        <div class="welfaremain">
          <!--左边按钮区域 -->
          <div class="l_btn point">
            <!-- <div class="btnbox" :class="flbtnclick === 1 ? 'acbtnbox' : ''" @click="changebtn(1)"
                            style="margin-top: 0.4rem;">消耗奖励</div> -->
            <div
              class="btnbox"
              :class="flbtnclick === 2 ? 'acbtnbox' : ''"
              @click="changebtn(2)"
              style="margin-top: 0.4rem"
            >
              日常福利
            </div>
            <!-- <div class="btnbox" :class="flbtnclick === 3 ? 'acbtnbox' : ''" @click="changebtn(3)">
              对战排行
            </div> -->
            <!-- 奖励说明 -->
            <!-- <div class="tipsbox" v-show="flbtnclick === 3 ">
              奖励说明
              <div class="tips">
                <p>当日参加盲盒对战获得道具[T币]最多的玩家可上对战榜</p>
              </div>
            </div> -->
          </div>
          <!-- 中间分割 -->
          <div class="suline"></div>
          <!-- 右边显示区域 -->
          <div class="r_center">
            <!-- 广告区 -->
            <!-- 组件显示区域 -->
            <div class="content">
              <!-- 动态渲染区域 -->
              <!--日常福利组件 -->
              <div v-if="Dailywelfare">
                <!-- 头部按钮 -->
                <!-- <div class="erjibtnbox">
                  <div class="acejbtn">
                    <span>红包口令</span>
                  </div>
                </div> -->
                <!--内容区域 -->
                <div class="contentbox">
                  <div class="CDKput">
                    <span>红包口令</span>
                    <input
                      type="text"
                      style="text-align: center"
                      v-model="cdkvalue"
                      placeholder="请输入红包口令"
                    />
                    <div class="getcdk" @click="getred">立即领取</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { RedOpen, getrecord, Info, GetIntegralBoxApi } from "@/network/api.js";
import { mapState } from "vuex";
export default {
  props: {
    right: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      active: 0,
      welfare: false, //福利弹窗

      //福利弹窗内开关
      flbtnclick: 2, //侧边按钮默认点击
      changeerjibtn: 1, //title按钮样式点击
      consume: false, //消耗开关
      Dailywelfare: false, //日常福利开关
      cdkvalue: "", //CDK红包输入值
      competitiveness: false, //排行组件开关
      today: false, //今日排行
      yesterday: false, //昨日排行
      clarification: false, //奖励说明
      todaylist: [], //今日数据
      yesterdaylist: [], //昨日数据

      index: 0,
      // 消耗福利数据保存
      day_use: "", //今日消耗
      yesterday_reward: "", //昨日奖励
      limit_reward: "", //限时奖励
      history_reward: "", //历史领取
      join: false, //QQ群开关
      joinkefu: false, //客服开关

      // 客服图片链接，qq群图片跳转链接
      querydata: {
        qq_group_link: "",
        service_qq: "",
      },
    };
  },
  created() {
    this.getrecorddata();
    this.GetInfo();
  },
  mounted() {
    setTimeout(() => {
      document.getElementById("ymwl-kefu").style.display = "block";
    }, 2000);
  },
  computed: {
    ...mapState(["user"]),
  },
  watch: {
    $route(newVal) {
      if (newVal.name === "Home") {
        document.getElementById("ymwl-kefu").style.display = "block";
        document
          .getElementById("ymwl-kefu")
          .classList.remove("my-custom-styles");
        document.getElementById("ymwl-kefu").classList.add("my-custom-styles1");
      } else if (newVal.name === "About") {
        document.getElementById("ymwl-kefu").style.display = "block";
        document
          .getElementById("ymwl-kefu")
          .classList.remove("my-custom-styles1");
        document.getElementById("ymwl-kefu").classList.add("my-custom-styles");
      } else {
        document.getElementById("ymwl-kefu").style.display = "none";
      }
    },
  },
  methods: {
    async tocdkbox() {
      const params = {
        type: 4,
      };
      const res = await GetIntegralBoxApi(params);
      if (res?.data?.code === 200 && res?.data?.data?.length > 0) {
        this.$router.push({
          path: "/OpeningBox",
          query: {
            id: res?.data?.data[0].box_id,
            typeid: res?.data?.data[0].id,
          },
        });
      } else {
        this.$message.error("暂无内容");
      }
    },
    GetInfo() {
      Info().then((res) => {
        this.querydata = res.data.data;
      });
    },

    // 选中改变
    changeindex(v) {
      this.active = v;
    },
    // 侧边按钮控制
    changebtn(v) {
      this.flbtnclick = v;
      switch (v) {
        case 1:
          // this.consume = true;
          // this.Dailywelfare = false;
          // this.competitiveness = false;
          break;
        case 2:
          this.Dailywelfare = true;
          this.consume = false;
          this.competitiveness = false;
          this.yesterday = false;
          break;
        case 3:
          this.competitiveness = true;
          if ((this.competitiveness = true)) {
            this.today = true; //今日排行组件
            this.yesterday = false;
          }
          this.Dailywelfare = false;
          this.consume = false;
          this.changeerjibtn = 1;
          break;
      }
    },
    // title按钮控制
    changeerji(v) {
      this.changeerjibtn = v;
      switch (v) {
        case 1:
          this.today = true;
          this.yesterday = false;
          break;
        case 2:
          this.yesterday = true;
          this.today = false;
          break;
      }
    },
    ToRoute(str) {
      this.$router.push(str);
    },
    // 进度条方法
    format(percentage) {
      return percentage === 100 ? "满" : `${percentage / 10}/10`;
    },
    //获取cdk红包
    getred() {
      if (this.cdkvalue == "") {
        this.$message({
          message: "请输入CDK",
          type: "error",
        });
      } else {
        RedOpen(2, _, this.cdkvalue).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: `领取成功,金额为${res.data.data}`,
              type: "success",
            });
            this.cdkvalue = "";
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      }
    },

    // 获得消耗数据
    getrecorddata() {
      getrecord().then((res) => {
        if (res.data.code == 200) {
          this.day_use = res.data.data.day_use;
          this.history_reward = res.data.data.history_reward;
          this.limit_reward = res.data.data.limit_reward;
          this.yesterday_reward = res.data.data.yesterday_reward;
        }
      });
    },
    //显示加群效果
    joincodeenter() {
      this.join = true;
    },
    joincodeleve() {
      this.join = false;
    },
    // 显示客服
    joincodeenterkefu() {
      this.joinkefu = true;
    },
    joincodelevekefu() {
      this.joinkefu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#Rank {
  position: fixed;
  width: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 92;
  font-size: 0.12rem;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  .rankitem {
    width: 0.4rem;
    text-align: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 0.1rem;
    color: #cddafc;
    margin: 0.05rem auto;
    .imgbox {
      width: 0.4rem;
      height: 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
    .flbox {
      width: 0.5rem;
      height: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
  }

  a {
    text-decoration: none;
    color: #fff;
  }
}

// 福利中心盒子
.duwelfare {
  position: relative;
  top: 20%;
  left: 5%;
  width: 3.35rem;
  height: 3.4rem;
  // overflow: hidden;
  background: rgba(8, 8, 30, 0.8);
  border-radius: 0.04rem;
  border: 0.01rem solid #5d5d9b;
  .title {
    width: 100%;
    height: 0.52rem;
    display: flex;
    align-items: center;
    position: relative;

    .center {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 0.16rem;
      color: #dfdbff;
      margin: 0 auto;

      img {
        display: block;
        height: 1rem;
      }
    }
  }
  .line {
    width: 100%;
    height: 0;
    border: 0.01rem solid;
    border-image: linear-gradient(
        90deg,
        rgba(93, 93, 155, 0),
        rgba(93, 93, 155, 1),
        rgba(93, 93, 155, 0)
      )
      1 1;
  }
  .line2 {
    width: 100%;
    height: 0;
    border: 0.01rem solid;
    border-image: linear-gradient(
        90deg,
        rgba(93, 93, 155, 1),
        rgba(93, 93, 155, 0)
      )
      1 1;
  }
  .welfaremain {
    display: flex;
    .l_btn {
      width: 1.22rem;
      position: relative;
      .btnbox {
        width: 0.8rem;
        height: 0.24rem;
        border-radius: 0.02rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 0.12rem;
        color: #fff;
        line-height: 0.24rem;
        text-align: center;
        margin: 0.1rem auto;
        background: url("../../assets/images/Rank/mb-flbtn.png") no-repeat;
        background-size: 100%;
      }
      .acbtnbox {
        background: url("../../assets/images/Rank/mb-acflbtn.png") no-repeat;
        background-size: 100%;
      }
      .tipsbox {
        position: absolute;
        bottom: 0;
        left: 15%;
        width: 0.84rem;
        height: 0.9rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 0.1rem;
        color: #c5bbed;
        .tips {
          margin-top: 0.1rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.1rem;
          color: #8383b8;
        }
      }
    }
    .suline {
      height: 2.9rem;
      border: 0.01rem solid;
      border-image: linear-gradient(
          180deg,
          rgba(93, 93, 155, 1),
          rgba(93, 93, 155, 0)
        )
        1 1;
    }
    .r_center {
      width: 2.3rem;
      .content {
        width: 100%;
        .erjibtnbox {
          width: 100%;
          height: 0.4rem;
          display: flex;
          align-items: center;
          padding-left: 0.28rem;
          .erjibtn {
            margin-right: 0.2rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 0.12rem;
            color: #48485e;
            cursor: pointer;
          }
          .acejbtn {
            color: #9292d7;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 0.12rem;
          }
        }
        .contentbox {
          width: 100%;
          // height: 2.49rem;
          height: 100%;
          background: linear-gradient(
            180deg,
            rgba(8, 8, 30, 0) 0%,
            #08081e 72%
          );
          // 日常福利
          .CDKput {
            width: 100%;
            height: 1.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column;
            span {
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 0.12rem;
              color: #c5bbed;
              margin-bottom: 0.3rem;
            }
            input {
              width: 2.08rem;
              height: 0.24rem;
              border: 0;
              outline: none;
              background: #08081e;
              border: 0.01rem solid #32325d;
              font-family: PingFang SC, PingFang SC;
              font-weight: 300;
              font-size: 0.12rem;
              color: rgba(191, 191, 237, 0.5);
            }

            // 红包
            .getcdk {
              margin-top: 0.2rem;
              width: 0.9rem;
              height: 0.24rem;
              text-align: center;
              line-height: 0.24rem;
              border-radius: 0.02rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 0.1rem;
              color: #ffffff;
              background: linear-gradient(135deg, #242448 100%, #42426d 100%);
              border: 0.01rem solid #5a5a9a;
              cursor: pointer;
            }
          }
          .datalisttitle {
            margin: 0 auto;
            width: 2.07rem;
            height: 0.32rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 0.12rem;
            color: #9e9e9e;
            border-radius: 0.02rem;
            border: 0.02rem solid #2f2f5e;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          // 排行css
          .datalistbg {
            width: 95%;
            height: 2.1rem;
            margin: 0rem auto;
            overflow-y: scroll;

            .listdata {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 0.32rem;
              background: #08080f;
              border: 0.01rem solid #2f2f5e;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 0.1rem;
              color: #cccccc;
              .userimg {
                display: block;
                width: 0.2rem;
                height: 0.2rem;
                border-radius: 50%;
                margin: 0.03rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
