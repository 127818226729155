var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{style:(`right:${_vm.right}`),attrs:{"id":"Rank"}},[_c('div',{staticClass:"rankitem",staticStyle:{"width":"1rem"}},[_c('div',{staticClass:"flbox",on:{"click":_vm.tocdkbox}},[_c('img',{attrs:{"src":require("../../assets/images/Rank/mb-rank-iconbox.png"),"alt":""}})])]),_c('div',{staticClass:"rankitem"},[_c('div',{staticClass:"imgbox",on:{"click":() => {
            (this.welfare = true), (this.Dailywelfare = true);
          }}},[_c('img',{attrs:{"src":require("../../assets/images/Rank/mb-rank-icon4.png"),"alt":""}})]),_c('p',[_vm._v("红包中心")])]),_c('a',{staticClass:"rankitem",attrs:{"target":"_blank","href":_vm.querydata.qq_group_link}},[_vm._m(0),_c('p',[_vm._v("一键加群")])]),_c('a',{staticClass:"rankitem",attrs:{"target":"_blank","href":`mqqwpa://im/chat?chat_type=wpa&uin=${_vm.querydata.service_qq}&version=1&src_type=web&web_src=oicqzone.com`}},[_vm._m(1),_c('p',[_vm._v("在线客服")])])]),_c('van-overlay',{attrs:{"lock-scroll":false,"show":_vm.welfare}},[(_vm.welfare)?_c('div',{staticClass:"duwelfare"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"center"},[_vm._v("活动福利")]),_c('img',{staticClass:"point",staticStyle:{"display":"block","width":"0.16rem","position":"absolute","right":"0.2rem"},attrs:{"src":require("@/assets/images/new/bg7.png"),"alt":""},on:{"click":() => {
              (this.welfare = false),
                (this.flbtnclick = 2),
                (this.changeerjibtn = 1),
                (this.yesterday = false);
            }}})]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"welfaremain"},[_c('div',{staticClass:"l_btn point"},[_c('div',{staticClass:"btnbox",class:_vm.flbtnclick === 2 ? 'acbtnbox' : '',staticStyle:{"margin-top":"0.4rem"},on:{"click":function($event){return _vm.changebtn(2)}}},[_vm._v(" 日常福利 ")])]),_c('div',{staticClass:"suline"}),_c('div',{staticClass:"r_center"},[_c('div',{staticClass:"content"},[(_vm.Dailywelfare)?_c('div',[_c('div',{staticClass:"contentbox"},[_c('div',{staticClass:"CDKput"},[_c('span',[_vm._v("红包口令")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cdkvalue),expression:"cdkvalue"}],staticStyle:{"text-align":"center"},attrs:{"type":"text","placeholder":"请输入红包口令"},domProps:{"value":(_vm.cdkvalue)},on:{"input":function($event){if($event.target.composing)return;_vm.cdkvalue=$event.target.value}}}),_c('div',{staticClass:"getcdk",on:{"click":_vm.getred}},[_vm._v("立即领取")])])])]):_vm._e()])])])]):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":require("../../assets/images/Rank/mb-rank-icon2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":require("../../assets/images/Rank/mb-rank-icon3.png"),"alt":""}})])
}]

export { render, staticRenderFns }